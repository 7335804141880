import { Box, Center, Flex, VStack } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";
import {
  OpaliteTextHighlighted,
  OpaliteTextNFTLink,
  OpaliteTextStandard,
  OpaliteTextTitle,
} from "../shared/opalite_text";
import { navigate } from "gatsby";

interface UrlCopyProps {}

const UrlCopy: React.FC<UrlCopyProps> = (props) => {
  return (
    <VStack
      padding="1.5rem"
      alignItems="center"
      justifyContent="center"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      height="100vh"
    >
      <Center
        textAlign="center"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <OpaliteTextTitle marginBottom="1.5rem">Success!</OpaliteTextTitle>
        <OpaliteTextStandard marginInline=".5rem" marginBottom="2rem">
          Your NFT collection has been minted! Now just pop over to your desktop
          to set up your wallet.
        </OpaliteTextStandard>
        <OpaliteTextNFTLink
          textAlign="left"
          width="100%"
          marginBottom="1rem"
          borderColor={COLORS_PRIMARY.FONT}
        >
          opalite.io/collection-nft-influencer
        </OpaliteTextNFTLink>
        <OpaliteTextStandard
          marginInline=".5rem"
          marginBottom="2rem"
          fontSize=".65rem"
        >
          {" "}
          Copy the link above to your clipboard, or click the link in your inbox
          to continue on your desktop device.
        </OpaliteTextStandard>
        <OpaliteButtonStandard onClick={() => navigate("/selection_screen")}>
          Back Home
        </OpaliteButtonStandard>
      </Center>
    </VStack>
  );
};

export default UrlCopy;
