import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Header from "../components/header/header";
import UrlCopy from "../components/url_copy/url_copy";

function UrlCopyPage() {
  return (
    <ChakraProvider>
      <Header />
      <UrlCopy />
    </ChakraProvider>
  );
}

export default UrlCopyPage;
